import React, { FormEvent, useState } from "react";
import { TextField, Button } from "@mui/material";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setEmailError(false);
    setPasswordError(false);

    if (email=== "") {
      setEmailError(true);
    }
    if (password === "") {
      setPasswordError(true);
    }

    if (email && password) {
      console.log(email, password);
    }
  };

  return (
    <React.Fragment>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          label="Mobile"
          onChange={(e) => setEmail(e.target.value)}
          required
          variant="outlined"
          color="primary"
          type="tel"
          sx={{ mb: 3 }}
          fullWidth
          value={email}
          error={emailError}
          size="small"
        />
        <TextField
          label="Create new password"
          onChange={(e) => setPassword(e.target.value)}
          required
          variant="outlined"
          color="primary"
          type="password"
          value={password}
          error={passwordError}
          fullWidth
          size="small"
          sx={{ mb: 3 }}
        />
        <TextField
          label="Confirm password"
          onChange={(e) => setPassword(e.target.value)}
          required
          variant="outlined"
          color="primary"
          type="password"
          value={password}
          error={passwordError}
          fullWidth
          size="small"
          sx={{ mb: 3 }}
        />
        <Button
          sx={{ marginTop: 4 }}
          variant="outlined"
          color="primary"
          type="submit"
        >
          Register
        </Button>
      </form>
    </React.Fragment>
  );
};

export default Register;
