import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";

const tiers = [
  {
    title: "Free",
    price: "0",
    description: [
      "10 users included",
      "1 device login",
      "Help center access",
      "No support",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
  },
  {
    title: "3 Months",
    price: "299",
    description: [
      "50 users included",
      "2 GB of storage",
      "Help center access",
      "Email support",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
  },
  {
    title: "6 Months",
    subheader: "Most popular",
    price: "499",
    description: [
      "100 contacts",
      "Single login",
      "Help center access",
      "Priority email support",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    title: "1 Year",
    price: "999",
    description: [
      "Unlimited contacts",
      "3 device login",
      "24 * 7 Support",
      "Profile visibility priority",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
];

export default function Pricing() {
  return (
    <Box sx={{ paddingBottom: 3, paddingTop: 3 }}>
      <Typography align="center" color={"primary"} p={4} variant="h4">
        Choose your best plan
      </Typography>

      <Grid container spacing={5}>
        {tiers.map((tier) => (
          // Enterprise card is full width at sm breakpoint
          <Grid item key={tier.title} xs={12} sm={6} md={3} lg={3} xl={3}>
            <Card>
              <CardHeader
                title={tier.title}
                titleTypographyProps={{ align: "center" }}
                action={tier.title === "Gold" ? <StarIcon /> : null}
                sx={{
                  backgroundColor: "white",
                  color : "black"
                  
                }}
              />
              <CardContent sx={{ backgroundColor: "black" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2,
                  }}
                >
                  <Typography component="h2" variant="h3" color="text.primary">
                    &#8377;{tier.price}
                  </Typography>
                </Box>
                <ul>
                  {tier.description.map((line) => (
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                      key={line}
                    >
                      {line}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
