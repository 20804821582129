import { Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import PageNotFound from "./pages/PageNotFoundPage";
import SearchProfilePage from "./pages/SearchProfilePage";
import UserDashboard from "./pages/UserDashboard";
import UserLayout from "./pages/UserLayout";
import UserProfilePage from "./pages/UserProfilePage";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/user" element={<UserLayout />}>
        <Route path="dashboard" element={<UserDashboard />} />
        <Route path="profile" element={<UserProfilePage />} />
        <Route path="searchProfile" element={<SearchProfilePage />} />
      </Route>
      <Route path="/settings" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export {};
