import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps
} from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import * as React from "react";
import ContactDetails from "../components/profile/ContactDetails";
import FamilyDetails from "../components/profile/FamilyDetails";
import OccupationDetails from "../components/profile/OccupationDetails";
import PersonalDetails from "../components/profile/PersonalDetails";
import QualificationDetails from "../components/profile/QualificationDetails";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box height={20}>
        <Typography variant="subtitle2" color="text.secondary">
          Profile&nbsp;Completion
        </Typography>
      </Box>

      <Box
        sx={{ width: "100%", mr: 1, ml: 1, display: "inline" }}
        component="div"
      >
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            borderRadius: 5,
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 5,
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function UserProfilePage() {
/*   const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setEmailError(false);
    setPasswordError(false);

    if (email === "") {
      setEmailError(true);
      setEmail('');
    }
    if (password === "") {
      setPasswordError(true);
    }

    if (email && password) {
      setPassword('');
      console.log(email, password, emailError, passwordError);
    }
  }; */

  const setPofileProgress = (progressValue: React.SetStateAction<number>) => {
    setProgress(progressValue);
  };

  React.useEffect(() => {
    // call api or anything
    console.log("Component has been rendered");
    setPofileProgress(50);
  });

  const [progress, setProgress] = React.useState(0);

  return (
    <Box>
      <Typography variant="h4" pb={2}>
        My&nbsp;Profile
      </Typography>
      {/* Profile Progress Bar section start */}
      <Box sx={{ width: "100%", mb: 2 }}>
        <LinearProgressWithLabel value={progress} />
      </Box>
      {/* Profile Progress Bar section end */}

      {/* Indicator section start */}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Paper elevation={15} sx={{ textAlign: "center", padding: "10px" }}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Profile Views
              </Typography>
              <Typography variant="h5" component="div">
                31
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper elevation={15} sx={{ textAlign: "center", padding: "10px" }}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Visited Profiles
              </Typography>
              <Typography variant="h5" component="div">
                56
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper elevation={15} sx={{ textAlign: "center", padding: "10px" }}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Blocked profiles
              </Typography>
              <Typography variant="h5" component="div">
                15
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper elevation={15} sx={{ textAlign: "center", padding: "10px" }}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Favourite Profiles
              </Typography>
              <Typography variant="h5" component="div">
                11
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {/* Indicator section end */}

      <PersonalDetails />

      <FamilyDetails/>

      <ContactDetails />

      <QualificationDetails />

      <OccupationDetails />

     
    </Box>
  );
}
