import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function PersonalDetails() {
  return (
    /* Personal Details */
    <Box
      sx={{
        bgcolor: "background.paper",
        borderRadius: 3,
        mt: 3,
      }}
    >
      <Typography variant="h6" p={2}>
        Personal Details
      </Typography>
      <Grid
        container
        pr={2}
        pl={2}
        pb={2}
        rowSpacing={1}
        columnSpacing={{ xs: 0, sm: 2, md: 3 }}
      >
        {/* Full Name Grid */}
        <Grid item xs={12}>
          <FormLabel>Full Name</FormLabel>
          <Grid container spacing={1} mt={0.1}>
            <Grid item xs={6} sm={4}>
              <TextField
                label="First"
                required
                variant="outlined"
                color="primary"
                type="tel"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                label="Middle"
                required
                variant="outlined"
                color="primary"
                type="tel"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Last"
                required
                variant="outlined"
                color="primary"
                type="tel"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Gender Row */}
        <Grid item xs={12}>
          <Box>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              sx={{ display: "inline" }}
            >
              Gender
            </FormLabel>
            &nbsp;&nbsp;&nbsp;
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              sx={{ display: "inline" }}
            >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
            </RadioGroup>
          </Box>
        </Grid>

        {/* Date of Birth Row */}
        <Grid item xs={12}>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Date of Birth &nbsp;
          </FormLabel>
          <Box mt={1}>
            <FormControl sx={{ minWidth: 90, mb: 1 }}>
              <InputLabel id="demo-simple-select-helper-label">Day</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Age"
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 90, ml: 1, mb: 1 }}>
              <InputLabel id="demo-simple-select-helper-label">
                Month
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Age"
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 90, ml: 1, mb: 1 }}>
              <InputLabel id="demo-simple-select-helper-label">Year</InputLabel>
              <Select
                labelId="-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Year"
              >
                <MenuItem value={10}>1990</MenuItem>
                <MenuItem value={20}>1991</MenuItem>
                <MenuItem value={30}>1992</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        {/* Address Row */}
        <Grid item xs={12}>
          <Box mt={1}>
            <FormControl sx={{ minWidth: 160, mb: 1 }}>
              <InputLabel id="demo-simple-select-helper-label">
                Caste
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Caste"
              >
                <MenuItem value={1}>Jain</MenuItem>
                <MenuItem value={2}>Maratha</MenuItem>
                <MenuItem value={1}>Buddhish</MenuItem>
                <MenuItem value={2}>Christain</MenuItem>
                <MenuItem value={2}>Sikh</MenuItem>
                <MenuItem value={2}>Parsi</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 160, ml: 1, mb: 1 }}>
              <InputLabel id="demo-simple-select-helper-label">Sub-Caste</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Sub-Caste"
              >
                <MenuItem value={1}>Digambar</MenuItem>
                <MenuItem value={2}>Saitwal</MenuItem>
                <MenuItem value={3}>Chaturth</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={9} sm={9}>
          <Box mt={2}>
            <TextField
              id="outlined-multiline-static"
              label="About me"
              placeholder="Write something about yourself ! It will show to profile visitor."
              multiline
              rows={4}
              fullWidth
            />
          </Box>
        </Grid>
      </Grid>
      <Button sx={{ m: 2 }} variant="outlined" color="primary" type="submit">
        Save
      </Button>
    </Box>
  );
}
