import {
  Box,
  Button,
  FormControl, InputLabel,
  MenuItem, Select,
  TextField
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function OccupationDetails() {
  return (
    /* Occupation Details */
    <Box
      sx={{
        bgcolor: "background.paper",
        borderRadius: 3,
        mt: 3,
      }}
    >
      <Typography variant="h6" p={2}>
        Occupation Details
      </Typography>
      <Grid
        container
        pr={2}
        pl={2}
        pb={2}
        rowSpacing={1}
        columnSpacing={{ xs: 0, sm: 2, md: 3 }}
      >
        {/* Full Name Grid */}
        <Grid item xs={12}>
          <Grid container spacing={1} mt={0.1}>
            <Grid item xs={6} sm={6}>
              <TextField
                label="Mobile"
                required
                variant="outlined"
                color="primary"
                type="tel"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                label="Email ID"
                required
                variant="outlined"
                color="primary"
                type="tel"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Address Row */}
        <Grid item xs={12}>
          <Box mt={1}>
            <FormControl sx={{ minWidth: 160, mb: 1 }}>
              <InputLabel id="demo-simple-select-helper-label">
                State
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="State"
              >
                <MenuItem value={1}>Maharashtra</MenuItem>
                <MenuItem value={2}>Karnataka</MenuItem>
                <MenuItem value={3}>Andhra Pradesh</MenuItem>
                <MenuItem value={1}>Maharashtra</MenuItem>
                <MenuItem value={2}>Karnataka</MenuItem>
                <MenuItem value={3}>Andhra Pradesh</MenuItem>
                <MenuItem value={1}>Maharashtra</MenuItem>
                <MenuItem value={2}>Karnataka</MenuItem>
                <MenuItem value={3}>Andhra Pradesh</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 160, ml: 1, mb: 1 }}>
              <InputLabel id="demo-simple-select-helper-label">City</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="City"
              >
                <MenuItem value={1}>Pune</MenuItem>
                <MenuItem value={2}>Thane</MenuItem>
                <MenuItem value={3}>Mumbai</MenuItem>
                <MenuItem value={1}>Pune</MenuItem>
                <MenuItem value={2}>Thane</MenuItem>
                <MenuItem value={3}>Mumbai</MenuItem>
                <MenuItem value={1}>Pune</MenuItem>
                <MenuItem value={2}>Thane</MenuItem>
                <MenuItem value={3}>Mumbai</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Button sx={{ m: 2 }} variant="outlined" color="primary" type="submit">
        Save
      </Button>
    </Box>
  );
}
