import {
  Box,
  Button,
  FormControl, InputLabel,
  MenuItem, Select,
  TextField
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function FamilyDetails() {
  return (
    /* Family Details */
    <Box
      sx={{
        bgcolor: "background.paper",
        borderRadius: 3,
        mt: 3,
      }}
    >
      <Typography variant="h6" p={2}>
      Family Details
      </Typography>
      <Grid
        container
        pr={2}
        pl={2}
        pb={2}
        rowSpacing={1}
        columnSpacing={{ xs: 0, sm: 2, md: 3 }}
      >
        {/* Full Name Grid */}
        <Grid item xs={12}>
          <Grid container spacing={1} mt={0.1}>
            <Grid item xs={6} sm={4}>
              <TextField
                label="Father Name"
                required
                variant="outlined"
                color="primary"
                type="tel"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                label="Mother Name"
                required
                variant="outlined"
                color="primary"
                type="tel"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Siblings Row */}
        <Grid item xs={12}>
          <Box mt={1}>
            <FormControl sx={{ minWidth: 180, mb: 1 }}>
              <InputLabel id="demo-simple-select-helper-label">No. of Brothers</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="No. of Brothers"
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 180, ml: 1, mb: 1 }}>
              <InputLabel id="demo-simple-select-helper-label">
              No.&nbsp;of&nbsp;Sisters
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="No. of Sisters"
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={9} sm={9}>
          <Box mt={2}>
            <TextField
              id="outlined-multiline-static"
              label="About Family"
              placeholder="Write something about your family !"
              multiline
              rows={4}
              fullWidth
            />
          </Box>
        </Grid>
      </Grid>
      <Button sx={{ m: 2 }} variant="outlined" color="primary" type="submit">
        Save
      </Button>
    </Box>
  );
}
