import { Box, Container } from "@mui/material";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../pages-css/HomePage.css";
import DownloadApp from "../components/DownloadApp";
import LoginRegisterPage from "../components/LoginRegisterComponent";
import Pricing from "../components/Pricing";
import Stories from "../components/Stories";

export default function HomePage() {
  return (
    <div className="bg">
      <Header />
      <Container>
        <LoginRegisterPage />
      </Container>
      <Box bgcolor={"#606060"}>
        <Container>
          <Pricing />
        </Container>
      </Box>
      <Box bgcolor={"black"}>
        <Container>
          <Stories />
        </Container>
      </Box>
      <Box bgcolor={"#606060"}>
        <Container>
          <DownloadApp />
        </Container>
      </Box>
      <Footer />
    </div>
  );
}

export { };

