import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";

export default function Header() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} sx={{opacity: [1, 1, 1],}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="Wedding Journey"
            sx={{ mr: 0 }}
          >
            <FavoriteIcon />
          </IconButton>
          <Typography
            variant="h6"
            fontStyle={"italic"}
            component="div"
            sx={{ flexGrow: 1 }}
          >
            Wedding Journey
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
export {};
