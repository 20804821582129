import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "black",
        mt: 5,
        pb: 3,
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={5}
          direction="row"
        >
          <Grid item xs={12} sm={3} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2" color="white">
              We are Wedding Journey company, dedicated to providing the best
              service to our customers.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Our Services
            </Typography>
            <Typography variant="body2" color="white">
              Matrimony Software Solutions
            </Typography>
            <Typography variant="body2" color="white">
              Data analytics
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="white">
              123 Main Street, Hinjewadi, Pune, India.
            </Typography>
            <Typography variant="body2" color="white">
              Email: help.wedding.journey@gmail.com
            </Typography>
            {/*             <Typography variant="body2" color="white">
              Phone: +91 83 9033 1955
            </Typography> */}
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Follow Us
            </Typography>
            <Link href="https://www.facebook.com/" color="text.primary">
              <Facebook />
            </Link>
            <Link
              href="https://www.instagram.com/"
              color="text.primary"
              sx={{ pl: 1, pr: 1 }}
            >
              <Instagram />
            </Link>
            <Link href="https://www.twitter.com/" color="text.primary">
              <Twitter />
            </Link>
          </Grid>
        </Grid>
      </Container>

      <Box mt={3}>
        <Divider />
        <Typography mt={2} align="center" color={"white"}>
          Copyright © Wedding Journey 2023.
        </Typography>
      </Box>
    </Box>
  );
}
