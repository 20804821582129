import androidstore from "../picture/android-playstore.png";
import applestore from "../picture/applestore.png";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export default function DownloadApp() {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent={"center"}
    >
      <Grid component={"div"} sx={{ display: "flex" , justifyContent: "center", padding : "20px", position : "initial"}}>
        <Typography variant="h6" color={"primary"}>Download Apps : &nbsp;&nbsp;</Typography>
        <img src={androidstore} alt="my" height={"30px"} width={"90px"} />&nbsp;&nbsp;
        <img src={applestore} alt="my" height={"30px"} width={"90px"} />
      </Grid>
    </Grid>
  );
}
